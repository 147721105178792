import styled from "styled-components"
import { Col, Container } from "react-bootstrap"
import { Constant } from "./Constants"

export const FootContainer = styled(Container)`
   
    background: linear-gradient(to right top, #778899, #788fa7, #7995b6, #7c9bc4, #7fa1d3, #7aabe0, #73b5ec, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vw 0 10vw 0;
    height: 100vh;

    @media (max-width:768px){
       
        height: 100vh;
    } 

`

export const ContainerDiv = styled(Container)`
    width: 80vw;
    height: 30vw;
    
    
    
    background: ${props => props.theme == "light" ? "white" : "#292929"};
    border-radius: 8px;
    transition: ${Constant.transition};
    

    @media (max-width:768px){
        width: 90vw;
        height: auto;
        overflow: hidden;
    }
`

export const COL = styled(Col)`
    display: grid;
    grid-template: 3;
    padding: 4vw 0 0 7vw;
    
    @media (max-width:768px){
        padding: 7vw 10vw 0vw 12vw;
    }
`

export const P1 = styled.p`
    font-size: 2vw;
    font-weight: bold;
    color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark};
    transition: ${Constant.transition};

    @media (max-width:992px){
     font-size: 2.4vw;
   } 
    
    @media (max-width:768px){
        font-size: 6vw;
    }
`
export const P2 = styled.p`
    font-size: 1.4vw;
    color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark};
    transition: ${Constant.transition};


    @media (max-width:992px){
     font-size: 1.8vw;
   } 
    
    @media (max-width:768px){
        font-size: 4.2vw;
    }

`
export const A  = styled.a`
    font-weight: bold;
    text-decoration: underline skyblue;
    color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark};
    transition: ${Constant.transition};

`

export const H1 = styled.h1`
    font-size: 3.5vw;
    transform: translate(5vw, 50%);
    font-weight: bolder;
    align-self: end;
    color: ${props => props.theme == "light" ? "#303040" : Constant.colorDark};
    transition: ${Constant.transition};

    @media (max-width:768px){
        transform: translate(42.5vw, 25vw);
        font-size: 6.5vw;
     
    }
    
`



export const COL2 = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: end;
    
`

export const FooterImg = styled.img`
    width: 29vw;
    height: auto;
    transform: translate(0vw, 0vw);

    @media (max-width:768px){
        width: 47vw;
       
    }
`