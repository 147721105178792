import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    theme: localStorage.getItem("theme") || "light",
}

const themeSlice = createSlice({
    name: "theme",
    initialState: initialState.theme,
    reducers: {
        toggleTheme: (state, action) => {
            state = action.payload;
            localStorage.setItem("theme", action.payload);
            return state
        }
    }
})

export default themeSlice.reducer;
export const {toggleTheme} = themeSlice.actions;