import { useDispatch, useSelector } from 'react-redux'
import { toggleTheme } from '../features/theme/themeSlice';
import { useEffect, useState } from 'react';

function CustomThemeDispatcher() {
  const theme = useSelector((state) => state.theme);
  const [themeState, setThemeState] = useState(theme);

  useEffect(() => {
    if(themeState == "light"){
      setThemeState("dark")
    } else if (themeState == "dark"){
      setThemeState("light")
    } 
  }, [theme])
  
    const dispatch = useDispatch();
    const handleDispatch =  () => {
           dispatch(toggleTheme(themeState))
    }
  return handleDispatch
}

export default CustomThemeDispatcher
