import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FootContainer, COL, ContainerDiv, P1, P2, A, H1, COL2, FooterImg } from '../styled-components/StyledFooter'
import { useSelector } from 'react-redux'
import Pic from "../assets/images/file.webp"
import { LinkedinLogo } from '@phosphor-icons/react'

function Footer() {
  const theme = useSelector((state) => state.theme)
  return (
    <FootContainer fluid>
      <ContainerDiv fluid theme={theme}>
          <Row style={{height: "100%"}}>
            <COL md={{span: 6}}>
              <div>
              <P1 theme={theme}>I'm always up for a chat.</P1>

              <P2 theme={theme}><A theme={theme} href='mailto:hey@jishan.in'>Pop me an email </A>at hey@jishan.in<br/> or give me a shout out on social media.</P2>
              <p><a href='https://www.linkedin.com/in/jishan-arzoo-37788a25b' target='_blank'><LinkedinLogo size={32} /></a></p>
              </div>

              <H1 theme={theme}>Hey there!</H1>

            </COL>

            <COL2 md={{span: 6}} className="zeropadcol">
              <FooterImg src={Pic} alt="cute-jishan" />
            </COL2>
          </Row>
      </ContainerDiv>
    </FootContainer>
  )
}

export default Footer
