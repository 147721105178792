import { Card, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import {Constant} from "./Constants"

export const ProjectContainer = styled(Container)`
  padding-top: 7vw;
  padding-bottom: 3vw;

  @media (max-width: 768px){
    padding-bottom: 10vw;
  }
`

export const H1 = styled.h1`
  color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark};
  transition: ${Constant.transition};
  margin-bottom: 1.5vw;
  font-weight: bolder;
  font-size: 15vw;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  
  background-image: ${props => props.theme == "light" ? "linear-gradient(to bottom,  #778899, #69bff8)" : "linear-gradient(to bottom, #778899, transparent)"} ;

  @media (max-width: 768px){
    margin-top: 2vh;
    margin-bottom: 0.3vh;
  }

`

export const CardCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5vw;
  padding: 1.5vw;

  @media (max-width: 768px){
      margin-top: 8vw;
    }
`

export const CARD = styled(Card)`
    width: 35vw;
    padding: 2vw 2vw 4vw 2vw;
    background: ${props => props.theme == "light" ? "#667788" : "#445566"};
    box-shadow: ${props => props.theme == "light" ? "0 5px 16px 0 #1f26875e" : null};
    /* transition: 0.2s ease-out; */

    @media (max-width: 768px){
      width: 82.5vw;
      padding: 4vw 4vw 7vw 4vw;
    }
    &:hover{
      /* background: linear-gradient(to top left, yellow, lightgreen, green, skyblue);  */
      /* background: linear-gradient(to right,  #222222, lightslategray); */
       /* background: radial-gradient(circle, #778899, #6688b9, #6584d7, #7f7aec, #ac65f6, #9676fd, #8284ff, #7090ff, #00b2ff, #00ceff, #00e6ff, #5ffbf1);  */
       /* background: radial-gradient(ellipse, #778899, #6688b9, #6584d7, #7f7aec, #ac65f6, #bb66f5, #ca67f3, #d768f1, #c786ee, #be9de4, #bbb0d5, #c0c0c0); */
       /* background: radial-gradient(ellipse, #778899, #788fa7, #7995b6, #7c9bc4, #7fa1d3, #7aabe0, #73b5ec, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1); */
       background: linear-gradient(to right top, #778899, #788fa7, #7995b6, #7c9bc4, #7fa1d3, #7aabe0, #73b5ec, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
       cursor: pointer;
       border: none;
      }

      `
export const IMG = styled(Card.Img)`
    width: auto;
    height: auto;

    
`
export const  CardTitle = styled(Card.Title)`
margin: 1.5vw 0vw 0.8vw 0.5vw;
  /* color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark}; */
  color: white;
  transition: ${Constant.transition};

  @media (max-width: 768px){
    margin-top: 4vw;
    font-size: 5.5vw;
  }
  
`

export const  CardText = styled(Card.Text)`
  /* color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark}; */
  color: white;
  transition: ${Constant.transition};

  @media (max-width: 768px){
    margin-top: 1vw;
    font-size: 4vw;
  }
  
`
