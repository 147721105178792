import styled from "styled-components"
import { Col, Container } from "react-bootstrap"
import { motion } from "framer-motion"

export const HeaderContainer = styled(Container)`
/* background-color: ${props => props.theme == "light" ? "white" : "black"}; */
transition: 0.7s ease-in;
padding-bottom: 8.3vw;
`

export const Div = styled.div`
display: flex;
flex-direction: column;
align-items: left;
justify-content: center;
padding: 0vw 1vw 0 1vw;

@media (max-width: 768px) {
  padding: 0vw 2.5vw 0 2.5vw;
  align-items: center;
}
`

export const BlurDiv1 = styled.div`
    width: 25vw;
    height: 25vw;
    position: absolute;
    filter: blur(150px);
    opacity: 0.5;
    border-radius: 100%;
    /* background-color: #3e1454; */
    background-color: #778899;
    transform: translate(14vw, 8vw);

    @media (max-width: 768px){
        width: 32vw;
        height: 32vw;
        filter: blur(60px);
        transform: translate(0vw, -42vw);
    }
    @media (max-width: 480px){
       
        filter: blur(40px);
        transform: translate(-0.8vw, -65vw);
    }
`
export const BlurDiv2 = styled.div`
    width: 25vw;
    height: 25vw;
    position: absolute;
    filter: blur(180px);
    opacity: 0.5;
    border-radius: 100%;
    /* background-color: #3e1454; */
    background-color: #778899;
    transform: translate(-4vw, -12vw);

    @media (max-width: 768px){
        
        filter: blur(110px);
        transform: translate(-12vw, 0vw);
    }

    @media (max-width: 480px){
        
        filter: blur(50px);
        transform: translate(-12vw, -18vw);
    }
`
export const BlurDiv3 = styled.div`
    width: 25vw;
    height: 25vw;
    position: absolute;
    filter: blur(100px);
    opacity: 0.5;
    border-radius: 100%;
    /* background-color: #3e1454; */
    background-color: #778899;
    transform: translate(50vw, 0);

    @media (max-width: 768px){
        
        filter: blur(100px);
        transform: translate(15vw, 8vw);
    }
    
    @media (max-width: 480px){
        filter: blur(45px);
        transform: translate(14vw, -4vw);
    }
`


export const H1 = styled.span`
  font-size: 4vw;
  color: ${props => props.theme == "light" ? "#2f4f4f" : "white"};
  transition: 0.7s ease-in;

  @media (max-width: 768px) {
  font-size: 45px;
}

`
export const P = styled.p`
margin-top: 1vw;
font-size: 1.8vw; 
color: ${props => props.theme == "light" ? "#2f4f4f" : "white"};
transition: 0.7s ease-in;


@media (max-width: 768px){
  padding: 10px 13px 0 13px;
  font-size: 18px;
}
`

export const Contact = styled.button`

  width: fit-content;
  margin-top: 1vw;
  /* padding: 0.8vw; */

  border: none;
  text-decoration: none;

    background: #778899;
    color: #fff;
    border-radius: 100px;
    font-size: 30px;
   
    padding: 17px 26px;
    box-shadow: 0 8px 32px 0 #1f26875e;
    transition: 0.2s;

    &:active{
      
    background: black;
    }

  @media (max-width: 768px){
    font-size: 25px;
    padding: 10px 18px;
  }
`
export const ImgCOL = styled(Col)`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`

export const ImgDiv = styled.div`
margin: 3.5vw 0vw 0 0vw;
transform:  translateX(-4vw) scale(${props => props.scale});
transition: 0.7s ease-in;
display: flex;
justify-content: center;
align-items: center;
padding: 8vw 1vw 4vw 1vw;
background: ${props => props.theme == "light" ? "transparent" : "transparent"};
background-image: ${props => props.theme == "light" ? null : "linear-gradient(180deg, #778899, rgba(0, 16, 56, .12) 50%, hsla(0, 0%, 100%, 0))"} ;
border-radius: 100%;
box-shadow: ${props => props.theme == "light" ? "0 8px 32px 0 #1f26875e" : null};
transition: 0.7s ease-out;




@media (max-width: 768px) {
  margin: 8vw auto auto 50vw;
  transform: translateX(-50%) scale(${props => props.scale});
  padding: 12vw 2.5vw 0 2.5vw;
  width: 55vw;
  height: 55vw;
  
}
`
export const Img = styled.img`
  width: 20vw;
  height: auto;
  
  aspect-ratio: auto 500 / 500 ;
  transform: translate(0.5vw, -3.5vw);
    /* filter: drop-shadow(0 8px 32px  #1f26875e); */

  @media (max-width: 768px){
    width: 40vw;
    transform: translate(1vw,-10vw);
  }
`
