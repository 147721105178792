// Part 1:
import styled from "styled-components";
import { Col, Container } from "react-bootstrap";
import { Constant } from "./Constants";


export const SkillContainer = styled(Container)`
margin-top: 4vw;
`
export const H1 = styled.h1`
font-weight: normal;
  color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark};
    transition: ${Constant.transition};

    @media (max-width: 768px){
      margin-top: 2vh;
      margin-bottom: 2vh;
    }
` 
export const SkillCol = styled(Col)`
padding: 0 0vw 0 10vw;

@media (max-width: 768px){
    padding: 0 10vw 0 10vw;
}

`

export const SkillDiv =styled.div`
/* margin-left: 30vw;
transform: translateX(-50%); */
 width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5vw;

  @media (max-width: 768px){
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }

  @media (max-width: 480px){
        gap: 10vw;
    }
`
export const MappedSkillDiv = styled.div`
display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7vw;
`


export const BlurDiv1 = styled.div`
    width: 20vw;
    height: 20vw;
    position: absolute;
    filter: blur(160px);
    opacity: 0.3;
    border-radius: 100%;
    background-color: ${props => props.theme == "light" ? null : Constant.blurBg1};
    transform: translate(14vw, 14vw);
    transition: ${Constant.transition};


    @media (max-width: 768px){
        width: 32vw;
        height: 32vw;
        filter: blur(120px);
        transform: translate(30vw, -10vw);
    }
    @media (max-width: 480px){
      width: 52vw;
        height: 52vw;
        filter: blur(70px);
        transform: translate(14vw, 70vw);
    }
`
export const BlurDiv2 = styled.div`
    width: 20vw;
    height: 20vw;
    position: absolute;
    filter: blur(150px);
    opacity: 0.5;
    border-radius: 100%;
    background-color: ${props => props.theme == "light" ? null : Constant.blurBg2};
    transform: translate(-4vw, 2vw);
    transition: ${Constant.transition};


    @media (max-width: 768px){
        
        filter: blur(120px);
        transform: translate(-6vw, 0vw);
    }

    @media (max-width: 480px){
      width: 46vw;
        height: 46vw;
        filter: blur(90px);
        transform: translate(17vw, -38vw);
    }
`
export const BlurDiv3 = styled.div`
    width: 25vw;
    height: 25vw;
    position: absolute;
    filter: blur(170px);
    opacity: 0.3;
    border-radius: 100%;
    background-color: ${props => props.theme == "light" ? Constant.blurBg1 : Constant.blurBg3};
    transform: translate(50vw, 0);
    transition: ${Constant.transition};

    @media (max-width: 768px){
        
        filter: blur(120px);
        transform: translate(0vw, 50vw);
    }
    
    @media (max-width: 480px){
        filter: blur(60px);
        transform: translate(12vw, 275vw);
    }
`




export const ImgDiv = styled.div`
    background-color: ${props => props.theme == "light" ? "#556677" : "#292827" };
    /* background: linear-gradient(to right top, #778899, #788fa7, #7995b6, #7c9bc4, #7fa1d3, #7aabe0, #73b5ec, #69bff8, #52cffe, #41dfff); */
    /* box-shadow: ${props => props.theme == "light" ? "0 8px 32px 0 #1f26875e" : null}; */
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.8vw;
  height: 7.8vw;
  transition: ${Constant.transition};
  z-index: 99;


  @media (max-width: 480px){
        width: 30vw;
        height: 30vw;
    }
`

export const Img = styled.img`
    width: 4.88vw;
    /* filter: brightness(105%); */
    /* transition: 0.2s ease-in;

    &:hover{
        filter: brightness(120%);
    } */

    @media (max-width: 480px){
        width: 20vw;
    }
`

export const P = styled.p`
font-size: 1.63vw;
  color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark};
  transition: ${Constant.transition};


  @media (max-width: 480px){
        font-size: 4.75vw;
    }
`

export const RadarCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px){
    margin-top: 5vh;
  }
  
`

export const RadarDiv = styled.div`
  width: 42vw;
  height: 42vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;


  @media (max-width: 768px){
    width: 70vw;
    height: 70vw;
  }

  @media (max-width: 480px){
    width: 97vw;
    height: 97vw;

  }
`

export const H6 = styled.h6`
  transform: translateY(-3vw);
  transition: ${Constant.transition};
  color: ${props => props.theme == "light" ? Constant.colorLight : Constant.colorDark};
`









// Part 2: