import React from 'react'
import skills from "../../data/skills.json"
import {SkillDiv, MappedSkillDiv, ImgDiv, Img, P} from "../../styled-components/StyledSkills"
import { useSelector } from 'react-redux'


function Skill() {
  const theme = useSelector((state) => state.theme);
  return (
    <SkillDiv>
     {skills.map((skill, id) => {
      // console.log(id)
  return (
    <MappedSkillDiv key={id} >
      <ImgDiv theme={theme}>
        <Img src={skill.imageSrc} alt={skill.title} />
      </ImgDiv>
      <P theme={theme}>{skill.title}</P>
    </MappedSkillDiv>
  );
})}
    </SkillDiv>
  )
}

export default Skill
