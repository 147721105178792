import styled from "styled-components";
import {motion} from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import { Constant } from "./Constants";

export const ExpHeading = styled.h1`
    color: ${props => props.theme == "light" ? "black" : "white"};
    transition: ${Constant.transition};

    @media (max-width: 768px){
            margin-top: 3vh;
            margin-bottom: 2vh;
    }
`


export const ExpDiv = styled(Container)`
    margin-top: 5vw;
`

export const CanvasRow = styled(Row)`
    margin-top: 5vw;
`
export const Div1 = styled.div`
display: flex;
justify-content: center;
align-items: center;
`
export const CircleDiv1 = styled(motion.div)`
    width: 8vw;
    height: 8vw;
    /* margin: 4vw 0 0 10vw; */
    transform: translate(1vw, 0vw);
    background: ${props => props.theme == "light" ? "linear-gradient(to left, #778899, black)" : "linear-gradient(to left, #778899, #292425)"  } ;
    background-repeat: no-repeat;
    /* background-size: 0% 100%; */
transition: 0.4s ease-in;
    border-radius: 100%;
    z-index: 99;

    @media (max-width: 768px){
        width: 6vw;
        height: 6vw;
        display: none;

    }
`

export const LineDiv1 = styled(motion.div)`
    width: 30vw;
    height: 2vw;
    background:${props => props.theme == "light" ? "linear-gradient(to right, #778899, black)" : "linear-gradient(to right, #778899, #292425)"  };
    background-repeat: no-repeat;
transition: 0.2s ;
transition-delay: 0.4s;
    transform: translate(0vw, 0vw);

    @media (max-width: 768px){
        width: 8vw;
        display: none;

      
    }

`
export const ExpDiv1 = styled(motion.div)`
    width: 45vw;
    /* height: 15vw; */
    padding: 1vw 0 1vw 0;
    border-radius: 15px;
    color: ${props => props.theme == "light" ? "white" : "white"};
    background: ${props => props.theme == "light" ? "linear-gradient(to left, #778899, black)" : "linear-gradient(to left, #778899, #292425)" };
    /* background-size: ${props => props.inView ? "100% 100%" : "0% 100%"}; */
    background-repeat: no-repeat;
    transition: 0.7s ease-out;
    transition-delay: 0.6s;
    z-index: 99;
    /* transform: scaleX(-1); */
    /* transform: translate(50vw, -15.9vw); */

    @media (max-width: 768px){
        width: 95vw;
        padding: 3vw 0 1vw 0;
        transition-delay: 0s;

    }
`

export const ExpDiv2 = styled(motion.div)`
    width: 45vw;
    /* height: 15vw; */
    padding: 1vw 0 1vw 0;
    border-radius: 15px;
    color: ${props => props.theme == "light" ? "white" : "white"};
    background: ${props => props.theme == "light" ? "linear-gradient(to left, #778899, black)" : "linear-gradient(to left, #778899, #292425)" };
    /* background-size: ${props => props.inView ? "100% 100%" : "0% 100%"}; */
    background-repeat: no-repeat;
    transition: 0.7s ease-out;
    transition-delay: 0.6s;
    z-index: 99;
    transform: scaleX(-1);

    /* transform: translate(50vw, -15.9vw); */

    @media (max-width: 768px){
        width: 95vw;
        padding: 3vw 0 1vw 0;
        transition-delay: 0s;

    }
`


export const ExpCard = styled(Container)`
    height: 100%;
    /* transform: scaleX(-1) */
    /* margin-top: 5vw; */
`
export const ExpCard2 = styled(Container)`
    height: 100%;
    transform: scaleX(-1)
    /* margin-top: 5vw; */
`

export const ExpRow = styled(Row)`
    height: 100%;
`

export const LogoCol = styled(Col)`
   display: flex;
   justify-content: center;
   align-items: center;
   /* transform: translate(-4vw, 0); */
`
export const Logo = styled.img`
   width: 5vw;

   @media (max-width: 768px){
        width: 14vw;
       
    }
`
export const Role = styled.h1`
    font-size: 2.1vw;

    @media (max-width: 768px){
       font-size: 5vw;
    }
`
export const Org = styled.p`
    font-size: 1.3vw;

    @media (max-width: 768px){
       font-size: 4.3vw;
    }
`
export const Period = styled.p`
 font-size: 0.9vw;

 @media (max-width: 768px){
       font-size: 3.9vw;
    }
`
export const Experiences = styled.div`
    font-size: 1vw;

    @media (max-width: 768px){
       font-size: 3.7vw;
    }
`

export const CircleDiv2 = styled.div`
width: 8vw;
    height: 8vw;
    /* margin: 4vw 0 0 10vw; */
    transform: translate(-1vw, 0vw) scaleX(-1);
    background: ${props => props.theme == "light" ? "linear-gradient(to left, #778899, black)" : "linear-gradient(to left, #778899, #292425)"  } ;
    background-repeat: no-repeat;
    border-radius: 100%;
    z-index: 99;
    transition: 0.2s;
    transition-delay: 0.2s;

    @media (max-width:768px){
        display: none;
    }
`
export const LineDiv2 = styled.div`
    width: 2vw;
    height: 18vw;
    background:${props => props.theme == "light" ? "linear-gradient(to bottom, #778899, black)" : "linear-gradient(to bottom, #778899, #292425)"  };
    background-repeat: no-repeat;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    transform: translate(-6vw, -10vw);
    z-index: 0;
    transition: 0.2s;

    @media (max-width: 768px){
        height: 32vw;
        transform: translate(-6vw, -17vw);
        display: none;
    }
`

export const LineDiv2v1 = styled(motion.div)`
    width: 30vw;
    height: 2vw;
    background:${props => props.theme == "light" ? "linear-gradient(to right, #778899, black)" : "linear-gradient(to right, #778899, #292425)"  };
    background-repeat: no-repeat;
    transition: 0.2s ;
    transition-delay: 0.4s;
    transform: translate(0vw, 0vw) scaleX(-1);


    @media (max-width: 768px){
        width: 8vw;
        display: none;
    }

`

export const LineDiv3 = styled(motion.div)`
    width: 2vw;
    height: 25vw;
    background:${props => props.theme == "light" ? "linear-gradient(to top, #778899, black)" : "linear-gradient(to top, #778899, #292425)"  };
    background-repeat: no-repeat;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    transform: translate(6.15vw, -15vw);
    transition: 0.9s;
    z-index: 0;

    @media (max-width: 768px){
        height: 35vw;
        transform: translate(5vw, -19vw);
        display: none;

    }
`