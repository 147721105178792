import React from 'react'
import skills from "../data/skills.json"
import { Container, Row, Col } from 'react-bootstrap'
import Skill from './sub-components/Skill'
import { SkillContainer, SkillCol, H1, RadarCol, RadarDiv, H6, BlurDiv1, BlurDiv2, BlurDiv3 } from '../styled-components/StyledSkills'
import { useSelector } from 'react-redux'
import SkillRadar from './sub-components/SkillRadar'

function Skills() {
  const theme = useSelector((state) => state.theme);
  return (
    <SkillContainer fluid>
      <Row>
        <Col className='zeropadcol'>
          <H1 theme={theme} className='text-center'>Experience</H1>
        </Col>
      </Row>
      
        <Row className='mt-4'>
          <SkillCol sm={{span: 10, offset: 1}} md={{span: 5, offset:0}} >
            <BlurDiv1 theme={theme}/>
            <BlurDiv2 theme={theme} />
            <BlurDiv3 theme={theme} />
            <Skill />
          </SkillCol>

          <RadarCol md={{span: 6}} className="zeropadcol">
            <RadarDiv>
            <SkillRadar />
            <H6 theme={theme}>Proficiency Level &nbsp;( % )</H6>
            </RadarDiv>
          </RadarCol>
        </Row>
    </SkillContainer>
  )
}

export default Skills


