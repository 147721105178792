import React, { useRef, useState, useEffect } from 'react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import ScrollTrigger from 'gsap/src/ScrollTrigger'
import { Container, Row, Col, Button } from 'react-bootstrap'
import styled from 'styled-components'
import imgPath from "../assets/images/jishan.webp"
import { useSelector } from 'react-redux'
import keyframes from 'styled-components'
import {HeaderContainer, Div, BlurDiv1, BlurDiv2, BlurDiv3, H1, P, Contact, ImgDiv, Img} from "../styled-components/StyledHeader"

// gsap.registerPlugin(useGSAP, ScrollTrigger);

function Header() {
  const theme = useSelector((state) => state.theme);
  // const container = useRef();

  // useGSAP(() => {
  //   gsap.to(".ImgDiv",{
  //     // x:400,
  //     // y:250,
  //     scale:1,
  //     // rotation:360,
  //     opacity:1,
  //     duration:4,
  //     scrollTrigger: {
  //       trigger:".ImgDiv",
  //       // start: "top top",
  //       // end: "200vh top",
  //       scrub: 3
  //     }
  //   })
  // }, { scope : container});

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Calculate scaling factor based on scroll position
  const scala = 1 - scrollPosition / (window.innerHeight*0.4);
  const scale = Math.min(Math.max(scala, 0), 1);

  
  return (
    <HeaderContainer theme={theme} fluid>
      <Row>
        <Col xs={{order: 2}} md={{span: 5, offset:1, order: 1}} className="zeropadcol">
          <Div>
            <BlurDiv1></BlurDiv1>
            <BlurDiv2></BlurDiv2>
            <BlurDiv3></BlurDiv3>
            <div className='typewriter'>
            <H1 theme={theme}>
            Hi, I'm<br /><h1 className='name'>Jishan Arzoo</h1>
            </H1>
            </div>
          <P theme={theme}>
            An experienced MERN stack developer, Proficient in React and Node.
            Reach out if you'd like to learn more! 
          </P>
          <a href='mailto:hey@jishan.in'><Contact >Contact me</Contact></a>
          </Div>
          
        </Col>
        <Col xs={{order: 1}} md={{span: 4, offset: 1, order: 2}}  className="zeropadcol">
          <ImgDiv theme={theme} scale={scale} className='ImgDiv'>
            <Img src={imgPath} alt='main-photo' />
          </ImgDiv>
        </Col>
      </Row>
    </HeaderContainer>
  )
}

export default Header
