import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { AboutDiv, COL, FieldDiv, Img, H3, P } from '../styled-components/StyledAbout';
import img from "../assets/images/jishan2.webp"
import cursor from "../assets/images/about/cursorIcon.png"
import server from "../assets/images/about/serverIcon.png"
import mobileApp from "../assets/images/about/uiIcon.png"

function About() {
  const theme = useSelector((state) => state.theme);
  return (
    <AboutDiv theme={theme} >
      <Container fluid >
      <Row >
        <Col className="zeropadcol">
          <h1 className='text-center' style={{marginTop: "1vh"}}>Specializations</h1>
        </Col>
      </Row>

      <Row>
        <COL sm={{span: 0}} md={{span: 5}}  className="zeropadcol">
        <Img src={img} alt='Boy with laptop' />

        </COL>

        <Col  className="zeropadcol">
        <Container fluid>
          <Row>
            <Col className='zeropadcol'>
              <FieldDiv>
              <img src={cursor} alt="Frontend Representation" />
            <div>
              <H3>Frontend Developer</H3>
              <P>
                I'm a frontend developer with experience in building responsive
                and optimized sites
              </P>
            </div>
              </FieldDiv>
            </Col>
          </Row>

          <Row>
            <Col className='zeropadcol'>
            <FieldDiv>
              <img src={server} alt="Backend Representation" />
            <div>
              <H3>Backend Developer</H3>
              <P>
              I have experience developing fast and optimised back-end systems and APIs
              </P>
            </div>
              </FieldDiv>
            </Col>
          </Row>

          <Row>
            <Col className='zeropadcol'>
            <FieldDiv>
              <img src={mobileApp} alt="Mobile-App Representation" />
            <div>
              <H3>Mobile Application Development (Learning)</H3>
              <P>
             
              </P>
            </div>
              </FieldDiv>
            </Col>
          </Row>
        </Container>

        </Col>
      </Row>
      </Container>
    </AboutDiv>
  )
}

export default About
