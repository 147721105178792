import React, { useEffect, useRef, useState } from 'react'
import { ExpHeading, ExpDiv, CanvasRow, Div1, CircleDiv1, LineDiv1, ExpDiv1, ExpCard, ExpCard2, ExpRow, LogoCol, Logo, Role, Org, Period, Experiences, CircleDiv2, LineDiv2, LineDiv3, LineDiv2v1, ExpDiv2 } from '../styled-components/StyledExp'
import { useScroll, motion, useInView, inView } from 'framer-motion';
import { Row, Col, Stack } from 'react-bootstrap';
import history from "../data/history.json"
import { useSelector } from 'react-redux';

function Experience() {
    const theme = useSelector((State) => State.theme);
   
    const ref3 = useRef(null);
    const inView3 = useInView(ref3, {once: false});

    const ref4 = useRef(null);
    const inView4 = useInView(ref4, {once: false});
    const ref5 = useRef(null);
    const inView5 = useInView(ref5, {once: false});
    const ref6 = useRef(null);
    const inView6 = useInView(ref6, {once: false});
    

    // useEffect(() => {
    //   console.log("IS the element in view: ", inView1)
    // }, [inView1])

   
    
  return (
    <ExpDiv fluid>
      <Row>
        <Col className="zeropadcol">
<ExpHeading className='text-center' theme={theme}>History</ExpHeading>
        </Col>
      </Row>
      <CanvasRow>
        <Col className="zeropadcol">
          <motion.div>

          {history[0] ? (<Div1>
          <CircleDiv1 theme={theme} style={{backgroundSize: inView3 ? "100% 100%" : "0% 100%"}}></CircleDiv1>
        <LineDiv1 theme={theme}  style={{backgroundSize: inView3 ? "100% 100%" : "0% 100%"}}></LineDiv1>
        <ExpDiv1 theme={theme} ref={ref3} style={{backgroundSize: inView3 ? "100% 100%" : "0% 100%"}}>
          <ExpCard fluid>
            <ExpRow>
              <LogoCol xs={{span: 3}} className='zeropadcol'>
              <Logo src={history[0].imageSrc} alt={history[0].organisation} />
              </LogoCol>

              <Col xs={{span: 9}} className='zeropadcol'>
                <Stack direction='vertical'>
                  <Role>{history[0].role}</Role>
                  <Org>{history[0].organisation}</Org>
                  <Period>{history[0].startDate} - {history[0].endDate}</Period>
                  <Experiences>
                    <ul>
                    {history[0].experiences.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                    </ul>
                  </Experiences>
                </Stack>
              </Col>
            </ExpRow>
          </ExpCard>
        </ExpDiv1>
          </Div1>
) : (null)}

    {history[1] ? (<Div1 className='mt-5'>
     
     <ExpDiv2 theme={theme} ref={ref4} style={{backgroundSize: inView4 ? "100% 100%" : "0% 100%"}}>
       <ExpCard2 fluid>
         <ExpRow>
           <LogoCol  xs={{span: 3}} className='zeropadcol'>
           <Logo src={history[1].imageSrc} alt={history[1].organisation} />
           </LogoCol>

           <Col xs={{span: 9}} className='zeropadcol'>
             <Stack direction='vertical'>
               <Role>{history[1].role}</Role>
               <Org>{history[1].organisation}</Org>
               <Period>{history[1].startDate} - {history[1].endDate}</Period>
               <Experiences>
                 <ul>
                 {history[1].experiences.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                    </ul>
                
               </Experiences>
             </Stack>
           </Col>
         </ExpRow>
       </ExpCard2>
     </ExpDiv2>
     <LineDiv2v1 theme={theme} style={{backgroundSize: inView4 ? "100% 100%" : "0% 100%"}}></LineDiv2v1>
     <CircleDiv2 theme={theme} style={{backgroundSize: inView4 ? "100% 100%" : "0% 100%"}}></CircleDiv2>
     <LineDiv2 theme={theme} style={{backgroundSize: inView4 ? "100% 100%" : "100% 0%"}}></LineDiv2>

       </Div1>) : (null)}


       {history[2] ? (<Div1 className='mt-5'>
     
       <LineDiv3 theme={theme} style={{backgroundSize: inView6 ? "100% 100%" : "100% 0%"}}></LineDiv3>
       <CircleDiv1 theme={theme}  style={{backgroundSize: inView6 ? "100% 100%" : "0% 100%"}}></CircleDiv1>
        <LineDiv1 theme={theme}  style={{backgroundSize: inView6 ? "100% 100%" : "0% 100%"}}></LineDiv1>
        <ExpDiv1 theme={theme} ref={ref6} style={{backgroundSize: inView6 ? "100% 100%" : "0% 100%"}}>
          <ExpCard fluid>
            <ExpRow>
              <LogoCol xs={{span: 3}} className='zeropadcol'>
              <Logo src={history[2].imageSrc} alt={history[2].organisation}/>
              </LogoCol>

              <Col xs={{span: 9}} className='zeropadcol'>
                <Stack direction='vertical'>
                  <Role>{history[2].role}</Role>
                  <Org>{history[2].organisation}</Org>
                  <Period>{history[2].startDate} - {history[0].endDate}</Period>
                  <Experiences>
                    <ul>
                    {history[2].experiences.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                    </ul>
                  </Experiences>
                </Stack>
              </Col>
            </ExpRow>
          </ExpCard>
        </ExpDiv1>

       </Div1>
) : (null)}
          
          

  {history[3] ? (<Div1 className='mt-5'>
     
  <ExpDiv2 theme={theme} ref={ref5} style={{backgroundSize: inView5 ? "100% 100%" : "0% 100%"}}>
       <ExpCard2 fluid>
         <ExpRow>
           <LogoCol  xs={{span: 3}} className='zeropadcol'>
           <Logo src={history[3].imageSrc} alt={history[3].organisation}/>
           </LogoCol>

           <Col xs={{span: 9}} className='zeropadcol'>
             <Stack direction='vertical'>
               <Role>{history[3].role}</Role>
               <Org>{history[3].organisation}</Org>
               <Period>{history[3].startDate} - {history[1].endDate}</Period>
               <Experiences>
                 <ul>
                 {history[3].experiences.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                 </ul>
               </Experiences>
             </Stack>
           </Col>
         </ExpRow>
       </ExpCard2>
     </ExpDiv2>
     <LineDiv2v1 theme={theme} style={{backgroundSize: inView5 ? "100% 100%" : "0% 100%"}}></LineDiv2v1>
     <CircleDiv2 theme={theme} style={{backgroundSize: inView5 ? "100% 100%" : "0% 100%"}}></CircleDiv2>
     <LineDiv2 theme={theme} style={{backgroundSize: inView5 ? "100% 100%" : "100% 0%"}}></LineDiv2>

       </Div1>
) : (null)}
          



       
          
          </motion.div>
        </Col>
      </CanvasRow>
     


    </ExpDiv>
  )
}

export default Experience
