import styled from "styled-components";
import { Constant } from "./Constants";

export const FloatDiv = styled.div`
    position: fixed;
    width: 15vw;
    height: 3vw;
    border-radius: 30px;
    background: ${props => props.theme == "light" ? "#ffffff" : "#404040"};
    background: linear-gradient(to bottom, #778899, dodgerblue);
    box-shadow: 0 8px 32px 0 #1f26875e;
    margin-top: ${props => props.inview === true ? "115vh" : "80vh"};
    margin-left: 50vw;
    transform: translate(-50%,0%);
    z-index: 100;
    transition: 0.3s ease-out;

    @media (max-width: 768px){
        width: 55vw;
        height: 12vw;
        margin-top: ${props => props.inview === true ? "115vh" : "85vh"};
        transform: translate(-50%,-100%)
    }
`

export const ChildDiv = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`