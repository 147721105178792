import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

export const ParentContainer = styled(Container)`
transition: 0.7s ease-in;
background-color: ${props => props.theme == "light" ? "white" : "black"};
position: relative;
z-index: 3;
box-shadow: ${props => props.theme == "light" ? "0 8px 32px 0 #1f26875e" : "0 8px 32px 0 #1f26875e"};

`


export const Container2 = styled(Container)`
position: sticky;
left:0;
bottom: 0;
z-index: 0;
`