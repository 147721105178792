import React, { useRef, useState } from 'react'
import { Container, Col, Row, Card, Button } from 'react-bootstrap'
import { ProjectContainer, H1, CardCol, CARD, IMG , CardTitle, CardText} from '../styled-components/StyledProjects'
import projects from "../data/projects.json"
import { useSelector } from 'react-redux'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'

function Projects() {
  const handleRedirect = (path) => {
    window.location.href = path;
  };
  const cardRefs = useRef([]);

  const handleMouseEnter = (index) => {
    gsap.to(cardRefs.current[index], {
      rotationZ: -5, // Adjust the degree of rotation as per your requirement
      ease: "elastic.out(2.2, 0.7)", // Elastic effect
      duration: 0.6,
      transformOrigin: 'center center',
    });
    // gsap.killTweensOf(cardRefs.current[index]); // Cancel any ongoing animations
  };

  const handleMouseLeave = (index) => {
    gsap.killTweensOf(cardRefs.current[index]); // Cancel any ongoing animations
    gsap.to(cardRefs.current[index], {
      rotationZ: 0,
      ease: 'elastic.out(1, 0.6)',
      duration: 0.5,
      transformOrigin: 'center center',
    });
  };



  
  const theme = useSelector((state) => state.theme)

  return (
    <ProjectContainer fluid>
      <Row>
        <Col className="zeropadcol">
          <H1 theme={theme} className='text-center'>Projects</H1>
        </Col>
      </Row>

      <Row>
        <Col className="zeropadcol">
          <Container fluid>
            <Row >
              
            {projects.map((item, index) => (
              <CardCol key={index} md={{span: 6}} onClick={() => handleRedirect(item.demo)}>
                  <CARD ref={(element) => (cardRefs.current[index] = element)} 
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  theme={theme}>
              <IMG src={item.imageSrc} alt={item.title}/>

              <CardTitle theme={theme}>{item.title}</CardTitle>
              <CardText theme={theme}>{item.description}</CardText>
            </CARD>
            </CardCol>
          ))}
            </Row>
          </Container>
          
        </Col>
      </Row>
      
    </ProjectContainer>
  )
}

export default Projects
