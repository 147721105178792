import styled from "styled-components";
import { Col, Container } from "react-bootstrap";

export const AboutDiv = styled.div`
padding: 1vw 0 3vw 0;
width: 70vw;
background: ${props => props.theme == "light" ? "#778899" : "transparent"};

/* background: linear-gradient(to right top, #778899, #788fa7, #7995b6, #7c9bc4, #7fa1d3, #7aabe0, #73b5ec, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1); */
background-image: ${props => props.theme == "light" ? null : "linear-gradient(180deg, #778899, rgba(0, 16, 56, .12) 50%, hsla(0, 0%, 100%, 0))"} ;
box-shadow: ${props => props.theme == "light" ? "0 8px 32px 0 #1f26875e" : null};
color: white;
border-radius: 10px;
transition: 0.7s ease-in;
margin-left: 50vw;
transform: translateX(-50%);

@media (max-width: 768px){
    padding: 2vw 0 2.5vw 0;

  }

  @media (max-width: 480px){
    width: 95vw;
    padding: 2vw 0 10vw 0;
  }
`

export const  COL = styled(Col)`
display: flex;
align-items: center;
justify-content: center;
`

export const Img  = styled.img`
    width: 20vw;
    height: auto;
    border-radius: 100%;

    @media (max-width: 768px){
        display: none;
    }
`

export const FieldDiv = styled.div`
margin-top: 2vw;
display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  list-style: none;
  padding: 25px;
  background-image: linear-gradient(
    90deg,
    rgba(165, 215, 232, 0.42) 0%,
    skyblue 100%
  );
  background-size: 0% 100%;
  background-repeat: no-repeat;
  transition: 0.4s;
  @media (max-width: 768px){
    margin-top: 4vw;
  }
  &:hover{
    background-size: 100% 100%;
  }
 
`

export const H3 = styled.h3`
font-size: 1.7vw;


@media (max-width: 768px){
    font-size: 2.9vw;
    }

    @media (max-width: 480px){
        font-size: 18px;
  }
`

export const P = styled.p`
    font-size: 1.5vw;
    
    @media (max-width: 768px){
        font-size: 2.3vw;
    }
    @media (max-width: 480px){
        font-size: 16px;
  }
`