import React, { useEffect, useState } from 'react'
import { ChildDiv, FloatDiv } from '../styled-components/StyledNavBar'
import { Envelope, LinkedinLogo, MoonStars, Sun, TwitterLogo } from '@phosphor-icons/react'
import { useSelector } from 'react-redux'
import sound from "../assets/sounds/sound1.mp3"
import useCustomThemeDispatcher from '../utils/useCustomThemeDispatcher'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'


function FloatingNavBar(props) {
    // const [InView, setInView] = useState(true);
    const theme = useSelector((state) => state.theme);
    const dispatchFunction = useCustomThemeDispatcher();
    const ClickHandler = () => {
        const audio = new Audio(sound);
        audio.play();
        dispatchFunction();
        
      };
      // useEffect(() => {
      //   setTimeout(() => (
      //     setInView(props.NavInView)), 1000)
      //     console.log("setTimeout Executed", InView, props.NavInView)
      // }, [props.NavInView])
     
  return (
    <>
    <FloatDiv theme={theme} inview={props.NavInView} >
            <ChildDiv>
               
<a href='https://www.linkedin.com/in/jishan-arzoo-37788a25b' target='_blank'><FontAwesomeIcon icon={faLinkedin} style={{cursor: "pointer", color: "white", scale: "140%"}} /></a>
               <a href='mailto:hey@jishan.in'><Envelope size={25} style={{cursor: "pointer", color: "white"}} /></a>

               
                {theme == "light" ? (<><Sun size={25} style={{cursor: "pointer", color: "white"}} onClick={() => ClickHandler()}/></>) : (<><MoonStars size={25} style={{cursor: "pointer", color: "white"}} onClick={() =>  ClickHandler()}/></>)}
            </ChildDiv>
        </FloatDiv> 
    </>
  )
}

export default FloatingNavBar
